import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
// routes
import AllRoutes from '.';

const Routes = () => {
    return (
        <GoogleOAuthProvider clientId="222426156475-2vonmrs5hhp273vos3tnlglfepfppc63.apps.googleusercontent.com">
            <BrowserRouter>
            <AllRoutes />
        </BrowserRouter>
        </GoogleOAuthProvider>
        
    );
};

export default Routes;
